import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { Input} from '@mui/base/Input';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControl } from '@mui/base/FormControl';
import {Box, Typography} from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

const Reklamation = () => {
  const [raumField, raumMeta] = useField('raum');
  const [detailsField, detailsMeta] = useField('details');
  const [mangelField, , mangelhelpers] = useField('magelart');
  const [files, metaFiles, filehelpers] = useField('files');
  const { values } = useFormikContext();
  const { errors } = useFormikContext();

  const handleOptionChange = (event) => {
    mangelhelpers.setValue(event.target.value);
  };

  const handleFileChange = (event) => {
    const fileList = event.currentTarget.files;
    const updatedFiles = values.files ? [...values.files, ...fileList] : [...fileList];
    filehelpers.setValue(updatedFiles);
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...values.files];
    updatedFiles.splice(index, 1);
    filehelpers.setValue(updatedFiles);
  };

  const RadioBox = ({ icon, label, sublabel, value, checked, onChange, ...props }) => {
    let isChecked = false;
    if (mangelField.value === value){isChecked = true;} 
    const boxClassName = isChecked ? 'checked-box' : 'unchecked-box';
    return (
      <FormControlLabel className={boxClassName} control={<Radio {...props} checked={isChecked} onChange={onChange} value={value} />}
        label={
          <Box display="flex" sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', height: '100%'}}>
            <Box>
              {icon}
              <Typography variant="h4" mb={3}>{label}</Typography>
            </Box>
            <Box>
              <Typography>{sublabel}</Typography>
              {isChecked == true ? 
                <CheckRoundedIcon sx={{ fontSize: 60, marginTop: '56px', color: 'white', padding: '10px', backgroundColor: '#3A8787', borderRadius: '100%', border: '1px solid #3A8787'  }}/>: 
                <CheckRoundedIcon sx={{ fontSize: 60, marginTop: '56px', color: '#F0F2F5', border: '1px solid #F0F2F5',padding: '10px', backgroundColor: 'white', borderRadius: '100%'}}/>
              }
            </Box>
          </Box>
        }
      />
    );
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  return (
    <div>
      <h2>Reklamation</h2>
      <FormControl>
        <RadioGroup name="mangelart">
          <Grid container spacing={2} mb={8} mt={1}>
            <Grid item xs={12} md={4}>
              <RadioBox icon={<VisibilityOutlinedIcon className="mangel-icon" sx={{marginBottom:" 20px"}} />} label="Optischer Mangel" sublabel="Bitte beachten: Fügen Sie ein Bild mit Meterstab im Bildsausschnitt mit an" value="Optischer Mangel" checked={values.selectedOption === 'Optischer Mangel'} onChange={handleOptionChange} />
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioBox icon={<VolumeUpOutlinedIcon className="mangel-icon" sx={{marginBottom:" 20px"}}/>} label="Akustischer Mangel" sublabel="Bitte beachten: Fügen Sie eine Ton- oder Videoaufnahme mit an" value="Akustischer Mangel" checked={values.selectedOption === 'Akustischer Mangel'} onChange={handleOptionChange} />
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioBox icon={<SettingsOutlinedIcon className="mangel-icon" sx={{marginBottom:" 20px"}}/>} label="Funktionaler Mangel" sublabel="Bitte beachten: Fügen Sie eine Bild oder Videoaufnahme an" value="Funktionaler Mangel" checked={values.selectedOption === 'Funktionaler Mangel'} onChange={handleOptionChange} />
            </Grid>
          </Grid>
        </RadioGroup>        
      </FormControl>
      <h5>Details</h5>
      <Grid container spacing={2} mt={0} mb={4}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl {...raumField} className='wb-input-wrapper' error={raumMeta.touched && Boolean(errors.raum)}>
                <label>Wo befindet sich der Mangel genau?</label>
                <Input  type="text" name="raum"/>
                {raumMeta.touched && errors.raum && <Typography sx={{ color: 'red' }}>{errors.raum}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className='wb-input-wrapper' error={detailsMeta.touched && Boolean(errors.details)}>
                <label>Detailbeschreibung</label>
                <BaseTextareaAutosize {...detailsField} minRows={5} maxRows={5} name="details" aria-label="details" />
                {detailsMeta.touched && errors.details && <Typography sx={{ color: 'red' }}>{errors.details}</Typography>}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <h5>Dateien</h5>
      <FormControl>
        <Grid container spacing={2} my={0}>
          {files.value && (
              files.value.map((file, index) => (
                <Grid item xs={12} md={4} >
                  <Box p={3} sx={{border: '1px solid #F0F2F5',}}>
                    <Typography pb={.5}>{file.name}</Typography>
                    <Typography pb={2} sx={{color: '#8C939C'}}>Größe: {(file.size/1e+6).toFixed(2)} MB</Typography>
                    <Button onClick={() => handleFileRemove(index)} sx={{color: '#3a8787', textTransform: 'unset', p: 0}}>
                      <CloseIcon sx={{color: '#3a8787', fontSize: '20px'}}/> 
                      Entfernen
                    </Button>
                  </Box>
                </Grid>
              ))
            )}
          <Grid item xs={12} md={4} >
            <Box p={3} sx={{border: '1px solid #F0F2F5', height: '100%'}}>
            <Button component="label" sx={{display: 'flex', flexDirection: "column", height: '100%', textTransform: 'unset'}}> 
              <AddCircleIcon sx={{color: '#3a8787', fontSize: '40px', mb: 2}}/>
              <Typography sx={{color: '#3a8787'}}>Datei hochladen</Typography>
              <VisuallyHiddenInput type="file" multiple name="files" onChange={handleFileChange} />
            </Button> 
            </Box>
          </Grid>
        </Grid>
      </FormControl>
  </div>
  );
};

export default Reklamation;