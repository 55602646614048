import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

import SharePointService from '../services/sharepoint.service';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`userdocspanel-${index}`}
        aria-labelledby={`userdocs-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index, processstate) {
    return {
      id: `userdocs-${index}`,
      'aria-controls': `userdocspanel-${index}`,
      'data-process': processstate
    };
}

export default function UserDocuments({handleActiveState}) {
    var flats = [];
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue)
        handleActiveState( parseInt(event.target.attributes['data-process'].nodeValue) )
    };

    const [files, setFiles] = useState([]);
    const handleFiles = (e) => { setFiles(e)};

    const [working, setWorking] = useState(false);
    const handleWorking = (e) => { setWorking(e)};

    const GetSPFiles = async () => {
        handleWorking(true)
        let allFiles = await SharePointService.getUserDocuments()
        if (allFiles.message != 'Wrong Secret'){
            handleFiles(allFiles)
            const keyname = Object.keys(allFiles)[0];
            handleActiveState( allFiles[keyname].categories.length )
        }
        handleWorking(false)
    }

    const SPfetchFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file, file.gelesen)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const SPopenFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file, file.gelesen)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    const didNotRead = <span style={{top: '1px', padding: '0px 10px', color: '#3A8787', fontWeight: 'bold', textShadow: '#3A8787 1px 0 16px'}}>Neu!</span>

    useEffect(() => {
        try {
            GetSPFiles()
        } catch (e) {
            console.error(e);
        }

        
    }, []);


    var content = <Typography variant='h6' mt={4}>Keine Dateien zur anzeige gefunden</Typography>
    if (files.length != 0) {
        content = <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile onChange={handleChange} aria-label="basic tabs example">
                    {Object.entries(files).map( (flat, index) =>
                        <Tab label={flat[0]} {...a11yProps( index + 10, flat[1].categories.length)} />
                    )}
                </Tabs>
            </Box>
            { Object.keys(files).length == undefined ? '': 
                Object.entries(files).map((flat, index) =>
                    
                    <CustomTabPanel value={value} index={index}>
                        <Box>
                        {Object.entries(flat[1].categories.sort()).map(category =>
                            <Box sx={{ pt: 3 }}>
                                <h5 align="left" mb={0}>{category[1] ? category[1].substring(3): ''}</h5>
                                <Box sx={{ overflowX: "auto" }}>
                                <Table sx={{ minWidth: 150 }} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ fontWeight: "bold", '@media screen and (min-width: 960px)': { width: "50%"} }} >Dateiname</TableCell>
                                            {/* <Hidden mdDown>
                                                <TableCell sx={{ fontWeight: "bold", '@media screen and (max-width: 960px)': { textAlign: 'right'} }} >Kategorie</TableCell>
                                            </Hidden> */}
                                            <TableCell align="right" sx={{ fontWeight: "bold" }} >Geändert</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: "bold", width: "100px" }} >Download</TableCell>
                                        </TableRow>
                                    </TableHead> 
                                    {Object.entries(flat[1].files).sort((a,b) => a[1].name > b[1].name ? 1 : -1).map(file => 
                                        file[1].category === category[1] ?
                                            <TableRow>
                                                <TableCell sx={{'&:hover': {cursor: "pointer"}, '@media screen and (min-width: 960px)': { width: "50%"} }} onClick={() => SPopenFile(file[1])}>{file[1].gelesen ? '' : didNotRead}{file[1].name}</TableCell>
                                                {/* <Hidden mdDown>
                                                    <TableCell >{file[1].category.substring(3)}</TableCell>
                                                </Hidden> */}
                                                <TableCell align="right">{file[1].edited}</TableCell>
                                                <TableCell align="right" sx={{ width: "100px"}} onClick={() => SPfetchFile(file[1])}><DownloadIcon /></TableCell>
                                            </TableRow> 
                                            :
                                            ''
                                    )}
                                </Table>
                                </Box>
                            </Box>
                        )}
                        </Box>
                    </CustomTabPanel>
                )
            }                
        </>
    }

    return (
        <Box sx={{ width: '100%' }}>
            { working ? 
                <Box py={10}>
                    <CircularProgress />
                </Box>
            :
                content
            }
        </Box>
    );
}