import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/base/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from '../services/user.service';


import './forms/forms.css';

export default function UserEditModal() {
//
//  States / Form Fields
//

// Modal
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

// Form
const [firstname, setFirstname] = React.useState("");
const handleFirstname = (e) => {
    const x = e.target.value;
    setFirstname(x);
}

const [lastname, setLastname] = React.useState("");
const handleLastname = (e) => {
    const x = e.target.value;
    setLastname(x);
}

const [phone, setPhone] = React.useState("");
const handlePhone = (e) => {
    const x = e.target.value;
    setPhone(x);
}

const [email, setEmail] = React.useState("");
const handleEmail = (e) => {
    const x = e.target.value;
    setEmail(x);
}

const [mobile, setMobile] = React.useState("");
const handleMobile = (e) => {
    const x = e.target.value;
    setMobile(x);
}

const [adress, setAdress] = React.useState("");
const handleAdress = (e) => {
    const x = e.target.value;
    setAdress(x);
}

const [zip, setZip] = React.useState("");
const handleZip = (e) => {
    const x = e.target.value;
    setZip(x);
}

const [ort, setOrt] = React.useState("");
const handleOrt = (e) => {
    const x = e.target.value;
    setOrt(x);
}


//
//  Events
//
const navigate = useNavigate();
const handleSubmit = async (e) => {
    e.preventDefault()
    await UserService.updateUser(firstname, lastname, phone, email, mobile, adress, zip, ort)
    navigate('/dashboard')
    window.location.reload()
    
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    p: 4,
};

return (
    <div>
        <EditIcon onClick={handleOpen} sx={{float: 'right'}}/>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container sx={style}>
                    <h2 align='center' >Ihre Daten ändern</h2>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container mt={6} rowSpacing={1} columnSpacing={2} justifyContent={'center'}>
                            <Grid item xs={12} md={6}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>Vorname</label>
                                    <Input name="name" id="firstname" label="Vorname" variant="standard" onChange={handleFirstname} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl className='wb-input-wrapper' required type="text" >
                                    <label>Nachname</label>
                                    <Input id="lastname" label="Nachname" variant="standard" onChange={handleLastname} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>Telefon</label>
                                    <Input fullWidth id="phone" label="Telefon" variant="standard" onChange={handlePhone} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>E-Mail</label>
                                    <Input fullWidth id="email" label="E-Mail" variant="standard" onChange={handleEmail} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>Mobil</label>
                                    <Input fullWidth id="mobile" label="Mobil" variant="standard" onChange={handleMobile} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>Adresse</label>
                                    <Input fullWidth id="adress" label="Adresse" variant="standard" onChange={handleAdress} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>PLZ</label>
                                    <Input fullWidth id="zip" label="PLZ" variant="standard" onChange={handleZip} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl className='wb-input-wrapper' required type="text">
                                    <label>Ort</label>
                                    <Input fullWidth id="ort" label="Ort" variant="standard" onChange={handleOrt} />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} display="flex" direction="column" justifyContent="center">
                            <Box pt={5} textAlign={"center"}>
                                <Button type="submit" className='btn prim'>Speichern</Button>
                            </Box>
                        </Grid>
                    </form>
            </Container>   
        </Modal>
    </div>
);
}