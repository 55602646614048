import axios from "axios";

 const API_URL = "https://wb-backend.unavio.de/user/";
//  const API_URL = "http://localhost:3001/user/";

const getCurrentUser = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  return axios
  .post(API_URL + "getcurrentUser", {}, config)
  .then((response) => {
    localStorage.setItem("user-data", JSON.stringify(response.data));
  });
};

const updateUser = async (firstname="", lastname="", phone="", email="", mobile="", adress="", zip="", ort="") => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  const data = {
    "userId": JSON.parse(localStorage.getItem("user")).id,
    "firstname": firstname,
    "lastname": lastname,
    "phone": phone,
    "email": email,
    "mobile": mobile,
    "adresse": adress,
    "zip": zip,
    "ort": ort
  }

  return await axios.put(API_URL + "updateUser", data, config)
}

const UserService = {
  getCurrentUser,
  updateUser,
}

export default UserService;