import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import { Button } from '@mui/base/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from './../assets/weinberger_logo_black.svg'
import LoginBG from './../assets/pfedelbachpost5-korrektur2-Kopie.jpg'
import PropTypes from 'prop-types';
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { Popper as BasePopper } from '@mui/base/Popper';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';


import AuthService from "../services/auth.service";


const Signup = () => {
    const [anrede, setAnrede] = useState("");
    const onChangeAnrede = (e, newValue) => { setAnrede(newValue) };

    const [firstName, setFirstname] = useState("");
    const onChangeFirstname = (e) => { setFirstname(e.target.value) };

    const [lastName, setLastname] = useState("");
    const onChangeLastname = (e) => { setLastname(e.target.value) };

    const [phone, setPhone] = useState("");
    const onChangePhone = (e) => { setPhone(e.target.value) };

    const [mobile, setMobile] = useState("");
    const onChangeMobile = (e) => { setMobile(e.target.value) };

    const [email, setEmail] = useState("");
    const onChangeEmail = (e) => { setEmail(e.target.value) };

    const [zip, setZip] = useState("");
    const onChangeZip = (e) => { setZip(e.target.value) };

    const [ort, setOrt] = useState("");
    const onChangeOrt = (e) => { setOrt(e.target.value) };

    const [adress, setAdress] = useState("");
    const onChangeAdress = (e) => { setAdress(e.target.value) };

    const [password, setPassword] = useState("");
    const onChangePassword = (e) => { setPassword(e.target.value) };

    const [secret, setSecret] = useState("");
    const onChangeSecret = (e) => { setSecret(e.target.value) };

    const [message, setMessage] = useState("");
    const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    
    AuthService.register(anrede, firstName, lastName, phone, mobile, email, zip, ort, adress, password, secret ).then(
      () => {
        navigate("/login");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  const Select = React.forwardRef(function CustomSelect(props, ref) {
    const slots = {
      root: StyledButton,
      listbox: Listbox,
      popper: Popper,
      ...props.slots,
    };
  
    return <BaseSelect {...props} ref={ref} slots={slots} />;
  });

  Select.propTypes = {
    slots: PropTypes.shape({
      listbox: PropTypes.elementType,
      popper: PropTypes.func,
      root: PropTypes.elementType,
    }),
  };

  const IconButton = React.forwardRef(function Button(props, ref) {
    const { ownerState, ...other } = props;
    return (
      <button type="button" {...other} ref={ref}>
        {other.children}
        <UnfoldMoreRoundedIcon />
      </button>
    );
  });

  IconButton.propTypes = {
    children: PropTypes.node,
    ownerState: PropTypes.object.isRequired,
  };

  const StyledButton = styled(Button, { shouldForwardProp: () => true })(
    ({ theme }) => `
    width: 100%;
    background-color: #f0f2f5;
    border-radius: 100px;
    border: 2px solid #f0f2f5;
    padding: 20px 30px 10px 30px;
    height: unset;
    text-align: left;
    
    &.Mui-expanded{
        border: 2px solid black;
    }
    `,
  );
  
  const Listbox = styled('ul')(
    ({ theme }) => `
    
    padding: 6px;
    margin: 12px 0;
    min-width: 320px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: white;
    border: 1px solid #f0f2f5;
    box-shadow: 0px 2px 4px 'rgba(0,0,0, 0.5)';
    `,
  );
  
  const Option = styled(BaseOption)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionClasses.selected} {
      background-color: #f0f2f5;
    }
  
    &.${optionClasses.highlighted} {
      background-color: #f0f2f5;
    }
  
    &:focus-visible {
      outline: 3px solid #1C2025;
    }
    
    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: #f0f2f5;
    }

    &:hover:not(.${optionClasses.disabled}) {
      background-color: #f0f2f5;
    }
    `,
  );
  
  const Popper = styled(BasePopper)`
    z-index: 1;
  `;

  return(
    <Grid container component="main">
      <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${LoginBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#f0f2f5',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
        <Grid container xs={12} sm={8} md={5} elevation={6}>
          <Box sx={{ my: 6 ,display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Box sx={{ textAlign: 'center'}}>
              <img style={{marginBottom: '20px'}} src={logo} />
              <h2>Kundenportal - Registrierung</h2>
            </Box>
            <Form onSubmit={handleSubmit} style={{width: "80%"}}>
              <Grid container spacing={2} my={4}>
                <Grid item xs={12}>
                    <Grid item xs={4}>
                    <FormControl required className='wb-input-wrapper'>
                        <label htmlFor="named-select">Anrede</label>
                        <Select   id="named-select" name="demo-select" value={anrede} onChange={onChangeAnrede}>
                            <Option value={"Herr"}>Herr</Option>
                            <Option value={"Frau"}>Frau</Option>
                            <Option value={"Herr und Frau"}>Herr und Frau</Option>
                        </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Vorname</label>
                    <Input  type="text" name="firstName" onChange={onChangeFirstname}/>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Nachname</label>
                    <Input  type="text" name="lastname" onChange={onChangeLastname}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <h4>Kontaktdaten</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Telefon</label>
                    <Input  type="text" name="phone" onChange={onChangePhone}/>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Mobil</label>
                    <Input  type="text" name="mobile" onChange={onChangeMobile}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>E-Mail</label>
                    <Input  type="text" name="email" onChange={onChangeEmail}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <h4>Adresse</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>PLZ</label>
                    <Input  type="text" name="zip" onChange={onChangeZip}/>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Ort</label>
                    <Input  type="text" name="ort" onChange={onChangeOrt}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Straße und Hausnummer</label>
                    <Input  type="text" name="adress" onChange={onChangeAdress}/>
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <h4>Account</h4>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Passwort</label>
                    <Input  type="password" name="password" onChange={onChangePassword}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>SharePoint-Secret</label>
                    <Input  type="text" name="secret" onChange={onChangeSecret}/>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center'}}>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                  <Button type="submit" className='btn prim' >Login</Button>
                </Grid>
              </Grid>
            </Form>
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://weinberger-immobilien.de/">
                WEINBERGER Objekt GmbH
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
  );
};

export default Signup;