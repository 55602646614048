import axios from "axios";

 const API_URL = "https://wb-backend.unavio.de/mailer/";
//  const API_URL = "http://localhost:3001/forms/";

const sendPasswordReset = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

    let response = await axios.post(API_URL + "getUserDocuments", {email: JSON.parse(localStorage.getItem("user")).email}, config)
    return response.data
};

const sendWarranty = async (data) => {     
  var formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key === 'files' && Array.isArray(value)) {
      value.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    } else {
      formData.append(key, value);
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  await axios.post(API_URL + "sendWarrantyMail", formData, config)
  return;
    
  };

const MailerService = {
    sendPasswordReset,
    sendWarranty
}

export default MailerService;