import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Kontaktdaten from './forms/_kontaktdaten';
import Reklamation from './forms/_reklamation';
import Dringlichkeit from './forms/_dringlichkeit';
import Terminierung from './forms/_terminierung';
import { Container ,Box, nativeSelectClasses } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button } from '@mui/base/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Hidden from '@mui/material/Hidden'


import Mailer from './../services/mailer.service'

const Warranty = () => {
    const steps = ['Kontaktdaten', 'Reklamation', 'Dringlichkeit', 'Terminierung'];
    const [step, setStep] = useState(0);
    const totalSteps = steps.length;

    const currentUser = JSON.parse(localStorage.getItem("user-data"))
    const [isFormValid, setIsFormValid] = useState(false);
    const [formValues, setFormValues] = useState({
        // Initialize with empty values for each field
        name: currentUser.firstname + ' ' + currentUser.lastname,
        email: currentUser.email,
        tel: currentUser.phone,
        whg: '',
        address: currentUser.adresse + ', ' + currentUser.zip + ' ' + currentUser.ort,
        project: '',
        raum: '',
        details: '',
        files: [],
        dringlichkeit: '',
        dringlichkeitDAYS: 0,
        termin1: '',
        termin2: '',
        termin3: '',
      });
    
    const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 0));
    const nextStep = () => {
        setIsFormValid(false)
        setStep((prevStep) => Math.min(prevStep + 1, totalSteps - 1));
    }

    const navigate = useNavigate();
    const navigateToDashboard = (e) =>{
        e.preventDefault();
        // navigate('/dashboard');
        // window.location.reload();
    }

    const handleSubmit = async (values, action) => {
        await Mailer.sendWarranty(values)
        navigateToDashboard();
        
    }

    // Validation function for each step
    const validateForm = (values) => {
        let errors = {};

        if (step === 0) {
            if (!values.name || !values.email || !values.tel || !values.whg || !values.address || !values.project) {
                errors = {
                ...errors,
                name: !values.name ? 'Required' : undefined,
                email: !values.email ? 'Required' : undefined,
                tel: !values.tel ? 'Required' : undefined,
                whg: !values.whg ? 'Required' : undefined,
                address: !values.address ? 'Required' : undefined,
                project: !values.project ? 'Required' : undefined,
                };
            }
        } else if (step === 1) {
           // Validation logic for Reklamation step
            if (!values.raum) { errors = { ...errors, raum: !values.raum ? 'Required' : undefined} }
            if (!values.details) { errors = { ...errors, details: !values.details ? 'Required' : undefined} }
            if (!values.magelart){ errors = { ...errors,
                    magelart: !values.magelart ? 'Required' : undefined
                }
            }
        } else if (step === 2) {
        // Validation logic for Dringlichkeit step
        if (values.dringlichkeit === undefined || values.dringlichkeit === null) {
            errors = {
                ...errors,
                dringlichkeit: 'Required',
            };
        }

        } else if (step === 3) {
            // Validation logic for Terminierung step
            if (!values.termin1) {
                errors = {
                    ...errors,
                    date1: 'Required',
                };
            }
            if (!values.termin2) {
                errors = {
                    ...errors,
                    date2: 'Required',
                };
            }
            if (!values.termin3) {
                errors = {
                    ...errors,
                    date3: 'Required',
                };
            }
        }
        setIsFormValid(Object.keys(errors).length === 0);
        return errors;
    };

  return (
    <Box sx={{background: "#F0F2F5", py: 3*3 }}>
        <Container sx={{background: "#1B1E21", padding: 2*3}}>
            <Grid container>
                <Grid item xs={12}>
                    <Hidden smDown>
                        <Stepper activeStep={step}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden smUp>
                        <Stepper activeStep={step} orientation='vertical'>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                </Grid>
            </Grid>
        </Container>
        <Container sx={{background: "white", pt: 4}}>
            <Formik
                initialValues={formValues}
                onSubmit={handleSubmit}
                validate={validateForm}
            >
                {(formikProps) => (
                    <Form enctype="multipart/form-data">
                        {step === 0 && <Kontaktdaten formikProps={formikProps} setFormValues={setFormValues}/>}
                        {step === 1 && <Reklamation formikProps={formikProps} setFormValues={setFormValues}/>}
                        {step === 2 && <Dringlichkeit formikProps={formikProps} setFormValues={setFormValues}/>}
                        {step === 3 && <Terminierung formikProps={formikProps} setFormValues={setFormValues}/>}


                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} py={5}>
                        {step > 0 && <Button className='btn sec float-left' type="button" onClick={prevStep}>Zurück</Button>}
                        {step < totalSteps - 1 && <Button type="button" className='btn prim float-right' onClick={nextStep} disabled={!isFormValid}>Weiter</Button>}
                        {step === totalSteps - 1 && <Button type="submit" className='btn prim float-right' disabled={!isFormValid}>Absenden</Button>}
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    </Box>    
  );
};

export default Warranty;