import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette:{
        primary: {
            main: '#3a8787'
        }
    },
    components: {
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    color: 'white',
                    
                    "&.Mui-active": {
                        color: 'white',
                    },

                    "&.Mui-completed": {
                        color: 'white',
                    }
                },
            },
        },
        MuiTableCell:{
            styleOverrides:{
                root:{
                    "&:hover":{
                        textDecoration: "underline"
                    }
                }
            }
        },

        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    "&.editUserModal:hover":{
                        cursor: "pointer"
                    }
                }
            }
        },
        MuiStepIcon:{
            styleOverrides:{
                root: {
                    color: 'white',
                    ".MuiStepIcon-text": {
                        fill: "#333"
                    },

                    "&.Mui-active": {
                        color: '#3A8787',

                        ".MuiStepIcon-text": {
                            fill: "white"
                        }
                    },

                    "&.Mui-completed": {
                        color: "#3A8787",
                        backgroundColor: 'aliceblue',
                        borderRadius: '100%',
                        transform: 'scale(1.01)',
                    },
                    
                },
                text:{
                    fill: 'red'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides: {
                root: {
                    padding: '32px 32px',
                    border: '1px solid #F0F2F5',
                    height: '100%',
                    marginLeft: '0px',
                    marginRight: '0px',

                    "&.unchecked-box .mangel-icon":{
                        color: '#3A8787'
                    },

                    "&.checked-box": {
                        backgroundColor: '#333333',
                        color: 'white',
                    }
                },
                label:{
                    height: '100%'
                },              
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.PrivateSwitchBase-root": {
                        display: "none"
                    }
                }
            }
        },
        MuiTypography:{
            styleOverrides: {
                root: {
                    "&.h4": {
                        align: 'left:'
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides:{
                root:{
                    height: '100%' 
                } 
            }
        }
    },
});

export default theme