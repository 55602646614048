import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from './../assets/weinberger_logo_black.svg';
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton';

export default function Header() {
  const navigate = useNavigate();

  const navigateToDashboard = (e) => {
    e.preventDefault();
    navigate('/dashboard');
  };

  const handleLogout = () => {
    AuthService.logout();
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: 64 }}>
      <AppBar position="fixed" sx={{ backgroundColor: 'white', textAlign: 'center', py: 1 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <img src={Logo} alt="Logo" onClick={navigateToDashboard} style={{ cursor: 'pointer' }} />
          </Box>
          <Hidden smUp>
            <IconButton color="primary" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Button color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
