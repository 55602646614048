import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import { Button } from '@mui/base/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from './../assets/weinberger_logo_black.svg'
import LoginBG from './../assets/pfedelbachpost5-korrektur2-Kopie.jpg'

import AuthService from "../services/auth.service";



const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const onChangePassword = (e) => {setPassword(e.target.value)};
  const navigate = useNavigate();
  const params = useParams();
  const { email, token } = params;

  const handleLogin = async (e) => {
    e.preventDefault();  
    await AuthService.resetPassword(password, email, token)
    // await delay(3000);
    // navigate("/Login");
    // window.location.reload();

  };

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://weinberger-immobilien.de/">
          WEINBERGER Objekt GmbH
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  return(
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: `url(${LoginBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
          <Box sx={{ mt: -9 ,display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Box sx={{ textAlign: 'center'}}>
              <img style={{marginBottom: '20px'}} src={logo} />
              <h2>Kundenportal</h2>
            </Box>
            <Form onSubmit={handleLogin}>
              <Grid container spacing={2} my={4}>
                <Grid item xs={12}>
                  <FormControl required className='wb-input-wrapper'>
                    <label>Neues Passwort</label>
                    <Input  type="password" name="password" onChange={onChangePassword}/>
                  </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <Box sx={{ textAlign: 'center'}}>
                        {message && (
                            <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                            </div>
                        )}
                        <Button type="submit" className='btn prim' >Passwort zurücksetzen</Button>
                    </Box>
                </Grid>
              </Grid>
            </Form>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
  );
};

export default ResetPassword;