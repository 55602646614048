import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import SharePointService from '../services/sharepoint.service';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function CommonDocuments() {
    var categories = [];
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {setValue(newValue)};

    const [files, setFiles] = useState([]);
    const handleFiles = (e) => { setFiles(e);};

    const [working, setWorking] = useState(false);
    const handleWorking = (e) => { setWorking(e)};

    const  GetSPFiles = async () => {
        handleWorking(true)
        handleFiles(await SharePointService.getCommonDocuments())
        handleWorking(false)
    }

    const SPfetchFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const SPopenFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    useEffect(() => {
        try {
            GetSPFiles()
            
        } catch (e) {
            console.error(e);
        }
    }, []);
    

    var content = <Typography variant='h6' mt={4}>Keine Dateien zur anzeige gefunden</Typography>
    content = <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile onChange={handleChange} textColor="primary" indicatorColor="primary">
                    {Object.entries(files).map((folder, index) => (
                        <Tab label={folder[0]} index={index}/>
                    ))}
            </Tabs>

            {Object.entries(files).map((folder, index) => (
                <CustomTabPanel value={value} index={index} >
                    {folder[0] === "Vorlagen Vermietung" && (
                        <Box p={0} sx={{ overflowX: "auto" }}>
                            <Table sx={{ minWidth: 150 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ fontWeight: "bold" }}>Dateiname</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: "bold" }}>Geändert</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: "bold", width: "100px" }}>Download</TableCell>
                                    </TableRow>
                                </TableHead>
                                {Object.entries(folder[1]).map((currentValue, currentIndex) => (
                                    Array.isArray(currentValue[1]) ? null : (
                                        <TableRow key={currentIndex}>
                                            <TableCell
                                                sx={{ '&:hover': { cursor: "pointer" } }}
                                                onClick={() => SPopenFile(currentValue[1])}>
                                                {currentValue[1].name}
                                            </TableCell>
                                            <TableCell align="right">{currentValue[1].edited}</TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => SPfetchFile(currentValue[1])}
                                                sx={{ cursor: 'pointer' }}>
                                                <DownloadIcon />
                                            </TableCell>
                                        </TableRow>
                                    )
                                ))}
                            </Table> 
                        </Box>         
                    )}
                    {folder[0] === "Bedienungsanleitungen_Pflegehinweise" && (
                        <Box>
                            {Object.entries(folder[1]).map((currentValue, currentIndex) => (
                                <Box sx={{ pt: 3 }}>
                                    <h5 align="left" mb={0}>{currentValue[0]}</h5>
                                    <Box sx={{ overflowX: "auto" }}>
                                        <Table sx={{ minWidth: 150 }} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" sx={{ fontWeight: "bold", width: "50%" }}>Dateiname</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }}>Kategorie</TableCell>
                                                    <TableCell align="right" sx={{ fontWeight: "bold" }}>Geändert</TableCell>
                                                    <TableCell align="right" sx={{ fontWeight: "bold", width: "100px" }}>Download</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {Object.entries(currentValue[1]).map((currentValue, currentIndex) => (
                                                Array.isArray(currentValue[1]) ? null : (
                                                    <TableRow key={currentIndex}>
                                                        <TableCell
                                                            sx={{ '&:hover': { cursor: "pointer" } , width: "50%"  }}
                                                            onClick={() => SPopenFile(currentValue[1])}>
                                                            {currentValue[1].name}
                                                        </TableCell>
                                                        <TableCell>{currentValue[1].kategorie}</TableCell>
                                                        <TableCell align="right">{currentValue[1].edited}</TableCell>
                                                        <TableCell
                                                            align="right"
                                                            onClick={() => SPfetchFile(currentValue[1])}
                                                            sx={{ cursor: 'pointer' }}>
                                                            <DownloadIcon />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            ))}
                                        </Table>
                                    </Box>
                                </Box>
                               
                            ))}          
                        </Box>
                    )}
                </CustomTabPanel> 
            ))}
                  
        </Box>
    </>

    return (
        <Box sx={{ width: '100%' }}>
            { working ? 
                <Box py={10}>
                    <CircularProgress />
                </Box>
            :
                content
            }
        </Box>

    );
}