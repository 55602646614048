import axios from "axios";

const API_URL = "https://wb-backend.unavio.de/auth/";
// const API_URL = "http://localhost:3001/auth/";

const register = (anrede, firstName, lastName, phone, mobile, email, zip, ort, adress, password, secret ) => {
  return axios.post(API_URL + "signup", {
    anrede,
    firstName,
    lastName,
    phone,
    mobile,
    email,
    zip,
    ort,
    adress,
    password,
    secret
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      email: username,
      password: password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const forgotPassword = async(username) => {
  return await axios.post(API_URL + "forgotpassword", {username});
}

const resetPassword = async(password, email, token) => {
  return await axios.post(API_URL + "resetpassword", {password, email, token});
}

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

const AuthService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getCurrentUser,
}

export default AuthService;